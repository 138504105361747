<template>
  <v-card>
    <v-card-title>{{ $t('routes.contenttypes.index') }}</v-card-title>
    <Datatable
      get_endpoint="contenttypes"
      :delete_endpoint="hasLaravelRoute('contenttypes.delete') ? 'contenttypes' : null"
      :header_items="headers"
      create_route="true"
      :create_action="
        () => {
          open_navigation_drawer = true
        }
      "
      :edit_route="hasVueRoute('contenttypes.edit') ? 'contenttypes.edit' : null"
      user_friendly_identifier="id"
      api_identifier="api_identifier"
      :item_on_click="
        item => {
          if (hasVueRoute('contenttypes.edit')) {
            this.$router.push({
              name: 'contenttypes.edit',
              params: { api_identifier: item.api_identifier },
            })
          }
        }
      "
      type="entity.contenttype"
    >
    </Datatable>
    <v-navigation-drawer
      v-model="open_navigation_drawer"
      width="400"
      temporary
      fixed
      :right="!$vuetify.rtl"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <h6 class="font-weight-semibold text-xl text--primary">
          {{ $t('table.add_entity', { entity: $t('entity.contenttype') }) }}
        </h6>
        <v-btn icon class="icon-customizer-close" @click="open_navigation_drawer = false">
          <v-icon>
            {{ mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <contenttype-add
        :callback="
          api_identifier => {
            open_navigation_drawer = false
            $router.push('/contenttypes/edit/' + api_identifier)
          }
        "
      />
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import Datatable from '@/components/Datatable/Datatable.vue'
import ContenttypeAdd from './ContenttypeAdd.vue'
import { mdiClose } from '@mdi/js'

export default {
  components: {
    Datatable,
    ContenttypeAdd,
  },
  data() {
    return {
      open_navigation_drawer: false,
      headers: [
        {
          text: 'label.id',
          value: 'id',
          search_type: 'number',
          sortable: true,
        },
        //TODO: fix that translation toggeles
        {
          text: 'label.name',
          data_format: 'translated',
          value: 'translations.' + this.$i18n.locale + '.name',
          search_type: 'text',
          sortable: true,
        },
        {
          text: 'label.updated_at',
          value: 'updated_at',
          search_type: 'date',
          data_format: 'date',
          sortable: true,
        },
        {
          text: 'label.updated_by',
          value: 'updated_by.email',
          search_type: 'text',
          sortable: true,
        },
      ],
      mdiClose,
    }
  },
}
</script>